import dynamic from 'next/dynamic'
import Head from 'next/head'
import { descripion } from '../constants/seo'
import LoadingComponent from '../components/shared/loadingComponent'

const Custom4404 = dynamic(() => import('../components/pagesCpmponents/custom404'), {
    loading: () => <LoadingComponent />,
})


export default function Custom404() {

    const title = '404 Error | FL Gold Coin Exchange'

    return (<>
            <Head>
                <title>{title}</title>
                <meta name="description" content={descripion} />
                <meta name="AdsBot-Google" content="noindex" />
                <meta name="robots" content="noindex, nofollow" />
            </Head>

            <Custom4404 />
        </>
    )
}